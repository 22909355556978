












































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle, BForm, BCardText
} from "bootstrap-vue";
import {VForm} from '@/global';


@Component({
  components: {
    BCardText,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle
  }
})
export default class IdibuAccount extends Vue {
  $refs!: {
    form: VForm
  }

  item: MaintenanceAPI.IdibuAccounts.Item = null;

  @Watch("id", {immediate: true})
  async watchId() {
    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  editing = false;

  get isEditing() {
    return (this.id == "create" || this.editing)
  }

  async getItem() {
    if (this.id == "create") {
      this.$set(this, "item", {
        "accountId": "",
        "accountName": "",
        "accountKey": "",
        "active": true,
        "listenerActive": false,
      });

      return;
    }

    const item = await this.$api.maintenance.idibuaccounts.Get(this.id);
    this.$set(this, "item", item);
  }

  async saveChanges() {
    if (!await this.$refs.form.validate()) return;

    if (this.editing)
      return await this.update();

    if (this.id == "create")
      return await this.create();
  }

  async update() {
    const res = await this.$api.maintenance.idibuaccounts.Update(this.item.id, {...this.item});
    this.editing = false;
    this.$set(this, "item", res);
  }

  async create() {
    const res = await this.$api.maintenance.idibuaccounts.Create({...this.item});
    if (res != null) {
      await this.$router.push({
        name: this.$route.name,
        params: {
          identifier: `${res.accountId}`
        }
      })
    }
  }
}
